import React, { useCallback, useEffect, useState } from "react"
import { PageProps, navigate } from "gatsby"

import { Layout, Modal } from "../components"
import { SubscribeCTA } from "../features/Subscribe/components"
import { ScenarioDisplay } from "../features/Scenario/components"
import { isClient } from "../lib/utils"

const ScenarioPage = ({ location }: PageProps): JSX.Element => {
  //TODO - use a path matching library to extract this - or a router
  const idMatch = location.pathname.match(/\/scenario\/([^/]*)\/?/)
  const signUp = location.pathname.match(/\/subscribe$/)

  const onGoToSubscribe = useCallback(() => {
    navigate(location.pathname + "/subscribe")
  }, [location])

  const goBackToScenario = useCallback(() => {
    navigate(location.href.replace("/subscribe", ""))
  }, [location])

  const isSubscribeVisible = !!signUp

  if (idMatch) {
    const scenarioId = idMatch[1].replace("/", "")
    return (
      <Layout>
        <ScenarioDisplay
          scenarioId={scenarioId}
          onShowSubscribePrompt={onGoToSubscribe}
        />
        <Modal isOpen={isSubscribeVisible} shouldCloseOnOverlayClick={false}>
          <SubscribeCTA onClose={goBackToScenario} />
        </Modal>
      </Layout>
    )
  } else {
    return <div>broken</div>
  }
}

export default ScenarioPage
